import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layoutHome"
import {
  Carousel,
  Container,
  Row,
  Col,
  //CardGroup,
  Card,
  CardDeck,
  Button,
  CardColumns,
  Image,
  Figure

} from 'react-bootstrap';
import { makeStyles} from '@material-ui/core/styles';
import { useStaticQuery, graphql } from "gatsby";
import { navigate } from "gatsby"
import {Typography,Grid,Paper} from '@material-ui/core'
import SEO from "../components/seo"
import 'bootstrap/dist/css/bootstrap.min.css';

const useStyles = makeStyles(theme => ({
  img: {
    display: "flex",
    justifyContent: "end",
    padding:"10px",
    '@media (max-width: 477px)': {
      justifyContent: "center",
    },
    },
    text:{
      display: "flex",
    },
    title:{
      width:"100%",
      display:"flex",
      justifyContent:"start",
      margin:"10px"
    },
    bodyText:{
      textAlign: "justify",
      whiteSpace: "pre-line"
    },
    imgFooter:{
      //width:"100%"
    },
    welcomText:{
      padding:"10px"
    },
    banner:{
      width: "100%",
      margin: "10px",
      display: "flex",
      justifyContent: "center",
      backgroundColor: '#29333D',
      color:"white",
      height:"110px",
      fontSize:"22px",
      fontStyle:"italic",
      fontFamily:"inherit",
      alignItems: "center",
      '@media (max-width: 477px)': {
        fontSize:"16px",
      },
    },
    carouselLabels:{
      color: 'white',
      fontWeight: 'bold',
      //fontFamily: 'Montserrat',
      background: "rgba(62, 72, 74, 0.5)",
      textAlign:"justify",
      '@media (max-width: 477px)': {
        display:"none"
      },
    },
    titleFont:{
      //fontFamily: 'Arial Black !important',
      color: 'white',
      fontWeight: 'bold',
      background: "rgba(62, 72, 74, 0.5)",
      '@media (max-width: 477px)': {
        fontSize:"14px"
      },
    },
    quienes:{
      paddingLeft:"4%",
      paddingRight:"4%"
    },
    Secction2:{
      width:"100%",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      marginBottom:"10px",
      textAlign:"justify",
      background: "rgba(71,  0,  0, 0.8)",
      color:"white",
      marginTop:20,
      marginBottom:0
    },
    carouselMobil:{
      minHeight:"200px"
    }
   
}));

const IndexPage = () => {
  const classes = useStyles(); 
  const home= useStaticQuery(graphql`query home {
    allCeformaPagesShow3 {
      edges {
        node {
          data {
            page {
              content {
                footerCarusel {
                  img
                  text
                  title
                }
                Banners {
                  img
                  text
                  title
                }
                CEFORMAText
                CEFORMATitle
                CeformaIMG
                CursoIMG
                CursoText
                CursoTitle
                lema
                principalIMG
                title
                welcomeText
                ImgText
              }
            }
          }
        }
      }
    }
  }
  `)
  console.log(home.allCeformaPagesShow3.edges[0].node.data.page.content)
  const Home = home.allCeformaPagesShow3.edges[0].node.data.page.content
  const carrusel = home.allCeformaPagesShow3.edges[0].node.data.page.content.footerCarusel
  return ( 
  <Layout>
    <SEO title="Inicio" />
    <Carousel  fluid interval={4000}  >
            {
              carrusel.map(item=>{
                return(
                  <Carousel.Item>
                    <img
                      className="d-block w-100 h-100"
                      src={item.img}
                      //src={"https://picsum.photos/1080/400"}
                      alt={item.title}
                  />
                  <Carousel.Caption className={classes.ca}>
                    <h3 className={classes.carouselLabels, classes.titleFont}>{item.title}</h3>
                    <p className={classes.carouselLabels}>
                      {item.text}
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                )
              })
            }
            
          </Carousel>

    <Grid container
    >
      <Grid iteam xs={12} sm={12} md={3} lg={3} xl={3} className={classes.img} >
       <Figure>
       <Figure.Image
          width={"100%"}
          height={"100%"}
          alt="171x180"
          src={Home.principalIMG}
        />
        <Figure.Caption className={classes.bodyText} >
        {Home.ImgText}
        </Figure.Caption>
       </Figure>
      </Grid>
      <Grid iteam xs={12} sm={12} md={8} lg={8} xl={8} className={classes.welcomText}  >
          <Typography className={classes.title} variant="h3" >
            {Home.title}
          </Typography>

        <Typography className={classes.bodyText} >
              {Home.welcomeText}
           </Typography>
      </Grid>
    </Grid>

    <Grid>


      <Grid container className={classes.quienes}>
      <Typography variant="h5" className={classes.Secction2} >
                      {Home.CEFORMATitle}
      </Typography>
      <Figure >
       <Figure.Image
          width={"100%"}
          height={"100%"}
          alt="Ceforma"
          src={Home.CeformaIMG}
        />
        <Figure.Caption className={classes.bodyText} >
            {Home.CEFORMAText}
        </Figure.Caption>
       </Figure>
      </Grid>

      <Grid container className={classes.quienes}>
      <Typography variant="h5" className={classes.Secction2} >
      {Home.CursoTitle}
      </Typography>
      <Figure >
       <Figure.Image
          width={"100%"}
          height={"100%"}
          alt="Ceforma"
          src={Home.CursoIMG}
        />
        <Figure.Caption className={classes.bodyText} >
            {Home.CursoText}
        </Figure.Caption>
       </Figure>
      </Grid>


      {
        Home.Banners.length > 0 ? (
          <Grid container 
          className={classes.full}
          >
        
          {Home.Banners.map((Article , index) => {
          return (
                  <Grid container className={classes.quienes} >
                     <Typography variant="h5" className={classes.Secction2} >
                        {Article.title}
                     </Typography>
                  <Paper className={classes.EventContainer}> 
                  <Figure >
                    <Figure.Image
                        width={"100%"}
                        height={"100%"}
                        alt="Ceforma"
                        src={Article.img}
                      />
                      <Figure.Caption className={classes.bodyText} >
                          {Article.text}
                      </Figure.Caption>
                    </Figure> 
                  </Paper>      
                  </Grid>
                  );
                })}
             
        </Grid>
          ) : (
            <Paper className={classes.Table}>
          
          </Paper>
     )}








    <Typography className={classes.banner} >
            {Home.lema}
    </Typography>

    </Grid>
  </Layout>
)}

export default IndexPage
